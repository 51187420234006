import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'
// import BigButton from '../../components/Ui/BigButton'

const Menu = () => {
  const metaTitle = 'Menus | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul'

  const backTopTopButton = () => {
    return (
      <div className='w-full flex mx-auto text-center'>
        {/* <BigButton text="Revenir en haut de la page" largeText={false} className="mx-auto" href="#topMenu" /> */}
      </div>
    )
  }

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Menu"
      />
      <div className="container content pb-12">

        {/* TOP IMAGE */}
        <div className="max-w-[2048px] max-h-[767px] pb-6">
          <StaticImage src="../../images/menu.png" alt="Image représentant un kiosque de boisson" imgClassName="rounded-3xl"/>
        </div>

        {/* HIGHLIGHTED TEXT */}
        <div className='my-12 text-center'>
          <p>Au Festif!, on sait que tous les goûts sont dans la nature. Que tu sois végétarien·ne, végan·e, ou sans gluten, on a pensé à toi! On s’assure que chaque bouchée soit aussi savoureuse que la précédente, en tenant compte des préférences de chacun·e. Avec des créations pleines de saveurs et de fraîcheur, avec des produits frais, de qualité et surtout, provenant de moins de 100 km autour de Baie-Saint-Paul, on veut que tu t’éclates autant dans ton assiette que dans la foule. Ne reste plus qu'à te laisser tenter par une assiette pleine de découvertes gustatives, de la plus classique à la plus audacieuse!</p>
          <br/>
          <p>Et si tu as soif, t’inquiète, l’équipe de <a href="https://rakatak.ca" target="_blank">Rakatak</a> s’occupe de toi! Derrière le bar pour te servir avec toute l'attention qu’il te faut, prépare-toi à savourer une sélection de boissons rafraîchissantes, de cocktails créatifs et de breuvages de qualité. Que tu sois plutôt bière locale, cocktail maison ou en quête de ton nouveau drink préféré, l’équipe de <a href="https://rakatak.ca" target="_blank">Rakatak</a> a tout prévu pour que chaque gorgée te mette de plus en plus dans le mood!</p>
        </div>

        {/* <div>
          <p className='italic'>Les scènes suivantes possèdent des bars, mais leurs menus ne figurent pas sur cette page: Rue Festive, Scène Loto-Québec, Quai Bell, Scène MicroBrasserie Charlevoix, Scène SiriusXM, Scène Hydro-Québec, Garage du curé Exclaim! en collaboration avec Lepointdevente.com et le Pit à sable Hydro-Québec.</p>
        </div> */}

        <div className='hidden'>
        <div id="topMenu" className='w-full md:flex block pt-24'>
          <div className='w-full bg-white rounded-3xl md:mx-4 mx-0'>
            <h2 className='text-center !mx-0 !px-0 !text-purple-wolf2024'>Parvis ICI Musique</h2>
            <p className='!mx-0 !px-0 text-center !text-purple-wolf2024'>17 juillet: 18h à 1ham<br/>18-19-20 juillet: 12h à 2h am<br/>21 juillet: 12h à 18h</p>
            <div className='!text-center flex'>
              {/* <BigButton text="Menu Bouffe" largeText={false} className="mx-auto md:mx-1" href="#parvis-bouffe" /> */}
              {/* <BigButton text="Menu Boisson" largeText={false} className="mx-auto md:mx-1" href="#parvis-boisson" /> */}
            </div>
          </div>
          <div className='w-full bg-white rounded-3xl md:mx-4 mx-0'>
            <h2 className='text-center !mx-0 !px-0 !text-purple-wolf2024'>Place Desjardins</h2>
            <p className='!mx-0 !px-0 text-center !text-purple-wolf2024'>18 - 19 - 20 juillet: 17h à 23h</p>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='!text-center flex'>
              {/* <BigButton text="Menu Bouffe" largeText={false} className="mx-auto md:mx-1" href="#desjardins-bouffe" /> */}
              {/* <BigButton text="Menu Boisson" largeText={false} className="mx-auto md:mx-1" href="#desjardins-boisson" /> */}
            </div>
          </div>
          <div className='w-full bg-white rounded-3xl md:mx-4 mx-0'>
            <h2 className='text-center !mx-0 !px-0 !text-purple-wolf2024'>Rue Festive</h2>
            <p className='!mx-0 !px-0 text-center !text-purple-wolf2024'>19 - 20 juillet: 12h à 18h</p>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='!text-center flex items-center justify-center'>
              {/* <BigButton text="Menu Bouffe" largeText={false} className="mx-auto md:mx-1" href="#ruefestive-bouffe" /> */}
              {/* <BigButton text="Menu Boisson" largeText={false} className="mx-auto md:mx-auto hidden" href="#ruefestive-bouffe" /> */}
            </div>
          </div>
          <div className='w-full bg-white rounded-3xl md:mx-4 mx-0'>
            <h2 className='text-center !mx-0 !px-0 !text-purple-wolf2024'>Camping Pronature à Maison Mère</h2>
            <p className='!mx-0 !px-0 text-center !text-purple-wolf2024'>18 - 19 - 20 juillet: 9h à 12h</p>
            <div className='!text-center flex items-center justify-center'>
              {/* <BigButton text="Menu Bouffe" largeText={false} className="mx-auto md:mx-1" href="#camping-bouffe" /> */}
              {/* <BigButton text="Menu Boisson" largeText={false} className="mx-auto md:mx-auto hidden" href="#camping-boisson" /> */}
            </div>
          </div>
        </div>
        <div className='w-full md:flex block mt-6'>
          <div className='w-full bg-white rounded-3xl md:mx-4 mx-0'>
            <h2 className='text-center !mx-0 !px-0 !text-purple-wolf2024'>Quai Bell / Expériences d’écoute / Camping du Fleuve</h2>
            <p className='!mx-0 !px-0 text-center !text-purple-wolf2024'>18 - 19 - 20 - 21 juillet: 8h à 21h</p>
            <div className='!text-center flex mt-1'>
              {/* <BigButton text="Menu Bouffe" largeText={false} className="mx-auto" href="#quai-bouffe" /> */}
              {/* <BigButton text="Menu Boisson" largeText={false} className="mx-auto" href="#quai-boisson" /> */}
            </div>
          </div>
          <div className='w-full bg-white rounded-3xl md:mx-4 mx-0'>
            <h2 className='text-center !mx-0 !px-0 !text-purple-wolf2024'>Hub Desjardins</h2>
            <p className='!mx-0 !px-0 text-center !text-purple-wolf2024'>18 juillet: 17h à 20h<br/>19 - 20 juillet: 12h à 20h</p>
            <br/>
            <br/>
            <br/>
            <div className='!text-center flex items-center justify-center'>
              {/* <BigButton text="Menu Bouffe" largeText={false} className="mx-auto md:mx-auto hidden" href="#hub-bouffe" /> */}
              {/* <BigButton text="Menu Boisson" largeText={false} className="mx-auto" href="#hub-boisson" /> */}
            </div>
          </div>
          <div className='w-full bg-white rounded-3xl md:mx-4 mx-0'>
            <h2 className='text-center !mx-0 !px-0 !text-purple-wolf2024'>Zone de services</h2>
            <p className='!mx-0 !px-0 text-center !text-purple-wolf2024'>18 - 19 - 20 - 21 juillet: 10h à 17h</p>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='!text-center flex items-center justify-center'>
              {/* <BigButton text="Menu Bouffe" largeText={false} className="mx-auto" href="#zone-bouffe" /> */}
              {/* <BigButton text="Menu Boisson" largeText={false} className="mx-auto md:mx-auto hidden" href="#zone-boisson" /> */}
            </div>
          </div>
        </div>

        {/* PARVIS BOUFFE */}
        <div>
          <div id="parvis-bouffe" className='pt-24'>
            <h2>Parvis ICI Musique - Bouffe</h2>
            <div className="billetterie flex flex-wrap w-full">

              <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                <div className="bg-white rounded-3xl">
                  <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                    <h3 className="leading-none mb-3 !px-1">Ah la vache !<br/>La fondue en cavale<br/>(options végés)</h3>
                  </div>
                  <div className="py-4 px-6">
                    <p className="mb-4 !px-2 !text-green-wolf2024"><b>Bretzfondü</b><br/>Pain bretzel bien rempli de notre fameuse fondue traditionnelle et tranche de saucisson des Charcuterie Charlevoisienne<br/>*Option végé disponible<br/>12$</p>
                  </div>
                </div>
              </div>

              <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                <div className="bg-white rounded-3xl">
                  <div className="bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3">Les Viandes Bio de<br/>Charlevoix<br/>(options sans gluten)</h3>
                  </div>
                  <div className="py-4 px-6">
                    <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Hot-dog bio Européen</b><br/>Pain ciabatta bio de Chacun son pain & notre fameuse saucisse de porc fraîche bio<br/>*Biologique, sans nitrite<br/>10$<br/>Extra choucroute de Les jardins Cap-Aux-Oies : 2.50$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Sandwich de porc effiloché bio</b><br/>Pain ciabatta bio de Chacun son pain & notre délicieux porc effiloché bio accompagné de fromage 1608 de la Laiterie Charlevoix<br/>*Biologique, sans nitrite<br/>10$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Assortiments de grelots</b><br/>Paquet mixte de charcuterie séchée style bouchée (nature, fromage hercule, champignon sauvage, noisette et muscade)<br/>*Biologique, Sans gluten, Sans nitrite<br/>8$</p>
                  </div>
                </div>
              </div>

              <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                <div className="bg-white rounded-3xl">
                  <div className="bg-pink-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3">Bistrot des artistes<br/>(options véganes)</h3>
                  </div>
                  <div className="py-4 px-6">
                    <p className="mb-4 !px-2 !text-pink-wolf2024"><b>Frites fraîches</b><br/>7$</p>
                    <p className="mb-4 !px-2 !text-pink-wolf2024"><b>Poutine Régulière</b><br/>Fromage à poutine de St-Fidèle, sauce fond de gibier, frites fraîches<br/>10$</p>
                    <p className="mb-4 !px-2 !text-pink-wolf2024"><b>Poutine au Sanglier</b><br/>Effiloché de sanglier de la Ferme Lamarre, fromage à poutine de St-Fidèle, sauce fond brun maison, frites fraîches<br/>12$</p>
                    <p className="mb-4 !px-2 !text-pink-wolf2024"><b>Poutine Végé au Tempeh</b><br/>Fromage à poutine de St-Fidèle, frites fraîches, tempeh québécois de Symbiose Alimentaire mariné et frit, sauce végé, sauce tomate maison, légumes<br/>*option végane (sans fromage) 11$<br/>13$</p>
                    <p className="mb-4 !px-2 !text-pink-wolf2024"><b>Poutine aux churros</b><br/>Sauce chocolat, mini guimauves<br/>11$</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="billetterie flex flex-wrap w-full md:mt-9 mt-0 mb-12">
              <div className='md:w-1/6 w-0 text-center px-4 my-0'></div>

              <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                <div className="bg-white rounded-3xl">
                  <div className="md:bg-red-wolf2024 bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                    <h3 className="leading-none mb-3">Faux Bergers</h3>
                  </div>
                  <div className="py-4 px-6">
                    <p className="mb-4 px-2 md:!text-red-wolf2024 !text-green-wolf2024"><b>Ailes de poulet confites rôties au charbon</b><br/>11.50$</p>
                    <p className="mb-4 px-2 md:!text-red-wolf2024 !text-green-wolf2024"><b>Mac’n’cheese chapelure aux herbes et à l’ail </b><br/>11.50$<br/>extra baloney 3$</p>
                    <p className="mb-4 px-2 md:!text-red-wolf2024 !text-green-wolf2024"><b>Brisket sandwich spontanés</b><br/>Prix du marché</p>
                  </div>
                </div>
              </div>

              <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                <div className="bg-white rounded-3xl">
                  <div className="md:bg-green-wolf2024 bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3">Tony & Charlo<br/>(options véganes)</h3>
                  </div>
                  <div className="py-4 px-6">
                    <p className="mb-4 !px-2 md:!text-green-wolf2024 !text-blue-wolf2024"><b>Burritos au bœuf</b><br/>Boeuf de Bovin Charlevoix, riz basmati, haricots rouges, poivrons<br/>11$</p>
                    <p className="mb-4 !px-2 md:!text-green-wolf2024 !text-blue-wolf2024"><b>Wrap vegan</b><br/>Avec légumes de nos maraîchers locaux<br/>11$</p>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

          {/* PARVIS BOISSON */}
          <div id="parvis-boisson" className='pt-24'>
            <h2>Parvis ICI Musique - Boisson</h2>
            <div className="billetterie flex flex-wrap w-full">

              <div className="md:w-1/6 w-full text-center px-4 md:my-0 my-2"></div>

              <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                <div className="bg-white rounded-3xl">
                  <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                    <h3 className="leading-none mb-3 !px-1">Espace Soif de la<br/>MicroBrasserie Charlevoix</h3>
                  </div>
                  <div className="py-4 px-6">
                    <p className="mb-4 !px-2 !text-green-wolf2024"><b>Ecocup consignable - 2$</b><br/><b>Bières</b></p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">BLNX - Premium Lager<br/>9$</p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Le Festif! - IPA<br/>9$</p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Blanche de Charlevoix - Blanche au blé<br/>9$</p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Vache Folle ESB - Bière rousse<br/>9$</p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Nano Festif! - Bière extra légère<br/>9$</p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Flacatoune - Bière blonde forte<br/>9$</p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Bière du moment - demande au bar!<br/>9$</p>

                    <p className="mb-4 !px-2 !text-green-wolf2024"><b>Cidres</b></p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Charlevoyou - Cidre pétillant sec<br/>12$</p>

                    <p className="mb-4 !px-2 !text-green-wolf2024"><b>Sans alcool</b></p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Bockale IPA sans alcool - Bière dorée de type ale - 0%<br/>9$</p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Ooya - Boisson énergisante<br/>5$</p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Jus, boissons gazeuses - Saveurs variées<br/>5$</p>
                    <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Montpellier - Eau pétillante<br/>5$</p>
                  </div>
                </div>
              </div>

              <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                <div className="bg-white rounded-3xl">
                  <div className="bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3">Bistro SAQ</h3>
                  </div>
                  <div className="py-4 px-6">
                    <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Ecocup consignable - 2$</b><br/><b>Vins</b></p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Rouge - Coteau Rougemont Versant Rouge, Québec<br/>9$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Blanc - Coteau Rougemont Versant Blanc, Québec<br/>9$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Blanc - Domaine de Lavoie, Québec<br/>9$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Rosé - Coteau Rougemont Versant Rosé, Québec<br/>9$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Rosé - Domaine de Lavoie, Québec<br/>9$</p>

                    <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Prêts-à-boire</b></p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Drav - Punch aux Fruits<br/>10$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Drav - Tom Collins<br/>10$</p>

                    <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Cocktails signature du festival - un cocktail différent chaque jour!</b></p>

                    <p className="mb-4 !px-2 !text-blue-wolf2024"><b>0,5% et moins - boissons faibles en alcool</b></p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Noroi Jalisco - Paloma<br/>9$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Bockale IPA sans alcool - Bière dorée de type ale 0%<br/>9$</p>

                    <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Boissons sans alcool</b></p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Ooya - Boisson énergisante<br/>5$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Montpellier - Eau pétillante<br/>5$</p>
                    <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Jus, boissons gazeuses - saveurs variées<br/>5$</p>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

          <div>
            <div id="desjardins-bouffe" className='pt-24'>
              <h2>Place Desjardins - Bouffe</h2>
              <div className="billetterie flex flex-wrap w-full">

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2"></div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3 !px-1">Faux Bergers</h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Sandwich Shish Taouk</b><br/><b>Légumes frais et marinés, aïoli, "hummus" de pois jaune<br/>12.50$</b></p>

                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Sandwich sabish aux œufs de canne</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Avec aubergine, tahini de graine de citrouille, ‘’hummus’’ de pois jaune<br/>12.50$</p>

                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Pomme de terre rôties, et sauce creton à Racer</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">11,50$</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

          <div>
            <div id="desjardins-boisson" className='pt-24'>
              <h2>Place Desjardins - Boisson</h2>
              <div className="billetterie flex flex-wrap w-full">

                <div className="md:w-1/6 w-full text-center px-4 md:my-0 my-2"></div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3 !px-1">Bar Côté Jardin</h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Situé près des restaurateur·ices</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024">Ecocup consignable - 2$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6"><b>Bière</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">BLNX - Premium Lager<br/>9$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Le Festif! - IPA<br/>9$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Blanche de Charlevoix - Blanche au blé<br/>9$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Vache Folle ESB - Bière rousse<br/>9$</p>

                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Vin</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Vin blanc du Québec<br/>9$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Vin rouge du Québec<br/>9$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Vin rosé du Québec<br/>9$</p>

                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Prêts-à-boire</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Drav - Punch aux fruits<br/>10$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Drav - Tom Collins<br/>10$</p>

                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Sans alcool</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Bockale IPA sans alcool - Bière dorée de type ale 0%<br/>9$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Amélanche - Tisane effervescente 0%<br/>10$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Ooya - Boisson énergisante - 5$<br/>5$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Jus, boissons gazeuses - saveurs variées<br/>5$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Montpellier - Eau pétillante<br/>5$</p>
                    </div>
                  </div>
                </div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3">Bar Côté Cour</h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Situé près des clôtures</b></p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024">Ecocup consignable - 2$</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6"><b>Bière</b></p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">BLNX - Premium Lager<br/>9$</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Le Festif! - IPA<br/>9$</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Nano Festif! - Bière extra légère<br/>9$</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Vache Folle ESB - Bière rousse<br/>9$</p>

                      <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Vin</b></p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Charlevoyou - Vin blanc mousseux<br/>12$</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Charlevoyou - Vin rosé mousseux<br/>12$</p>

                      <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Cidre</b></p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024">Charlevoyou - Cidre pétillant sec<br/>12$</p>

                      <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Prêts à boire</b></p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Charlevoyou - Limonade alcoolisée<br/>12$</p>

                      <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Sans alcool</b></p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Bockale IPA sans alcool - Bière dorée de type ale 0%<br/>9$</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Amélanche - Tisane effervescente 0%<br/>10$</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Ooya - Boisson énergisante<br/>5$</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Jus, boissons gazeuses - saveurs variées<br/>5$</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Montpellier - Eau pétillante<br/>5$</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

          <div>
            <div id="ruefestive-bouffe" className='pt-24'>
              <h2>Rue Festive - Bouffe</h2>
              <div className="billetterie flex flex-wrap w-full">

                <div className="md:w-1/6 w-full text-center px-4 md:my-0 my-2"></div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3 !px-1">Hôtel & Spa - Le Germain Charlevoix<br/>(options végés)</h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Sandwich d'échine de porc sauce bbq maison</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024">Porc de Québec, légumes de nos jardin, pain de Chacun son pain, miel de nos ruches<br/>12$</p>

                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Gaspaccio andalou glacé</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Mie de pain, concombre, tomate, oignon rouge, poivrons, piment gorria<br/>*végé<br/>8$</p>
                    </div>
                  </div>
                </div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3">La Secousse<br/>(options végés)</h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Pizza au four au bois - 13 pouces</b></p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024">Végés (2 options) et avec viandes (3 options)<br/>*100% local<br/>6$ la pointe ou 30$ la pizza complète</p>

                      <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Quesadillas</b></p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Végé (2 options) et avec viande (2 options)</p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024 !leading-6">Entre 11$ et 13$</p>

                      <p className="mb-4 !px-2 !text-blue-wolf2024"><b>Limonade maison</b></p>
                      <p className="mb-4 !px-2 !text-blue-wolf2024">3,75$</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

          <div>
            <div id="camping-bouffe" className='pt-24'>
              <h2>Camping Pronature à Maison Mère - Bouffe</h2>
              <div className="billetterie flex flex-wrap w-full">

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2"></div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3 !px-1">Oui Oui - Buvette Forestière<br/>(options végés)</h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Sandwich Dej</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024">Pain, oeuf, jambon de Charlevoix, fromage en grain de Saint-Fidèle<br/>8$</p>

                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Barre avoine et chocolat</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">Avoine Belle récolte, fruits séchés, graines de tournesol et citrouille, sirop de bouleau<br/>4$</p>

                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Pain doré et confiture de fruit de chez Lupin</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">9$</p>

                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Pain doré et champignons sautés</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024 !leading-6">10$</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

          <div>
            <div id="quai-bouffe" className='pt-24'>
              <h2>Quai Bell / Expériences d’écoute / Camping du Fleuve - Bouffe</h2>
              <div className="billetterie flex flex-wrap w-full">

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2"></div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3 !px-1">Chocolaterie Cynthia</h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Crème molle</b><br/>5$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Slush</b><br/>4.50$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Sunday</b><br/>7$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Pain doré et champignons sautés</b><br/>10$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Beignes, assortiments de petites viennoiseries</b><br/>2$ à 6$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Panini et Grilled cheese</b><br/>Dinde, jambon ou porc effiloché, garnis de tomate, salade et fromage; accompagné de chips.<br/>15.99$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Cafés</b><br/>4$ à 6$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Chocolat chaud</b><br/>6$</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

          <div>
            <div id="quai-boisson" className='pt-24'>
              <h2>Quai Bell / Expériences d’écoute / Camping du Fleuve - Boisson</h2>
              <div className="billetterie flex flex-wrap w-full">

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2"></div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3 !px-1">Café Charlevoix</h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Buddies</b><br/>5$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Latté Glacé</b><br/>5$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Matcha Limo</b><br/>5$</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

          <div>
            <div id="hub-boisson" className='pt-24'>
              <h2>Hub Desjardins - Boisson</h2>
              <div className="billetterie flex flex-wrap w-full">

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2"></div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3 !px-1"> </h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Ecocup consignable - 2$</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>OOYA Festif! individuel</b><br/>5$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>OOYA Festif! 4 pack</b><br/>16$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Limonade énergisante</b><br/>7$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024">Mango Bliss (Mangue passion, Flirt Mandarine, Wise Mangue)</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024">Framboise Fraîche (Framboise Melon d'eau, Flirt Framboise, Wise Fraise)</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024">Pêche Mandarine (Pêche kiwi Sans Sucre, Flirt Mandarine, Wise Mangue) </p>
                      <p className="mb-4 !px-2 !text-green-wolf2024">Limonade fusion (Original/ Original Sans Sucre, Flirt Basilic, Wise Pitaya)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

          <div>
            <div id="zone-bouffe" className='pt-24'>
              <h2>Zone de services - Boisson</h2>
              <div className="billetterie flex flex-wrap w-full">

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2"></div>

                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                  <div className="bg-white rounded-3xl">
                    <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                      <h3 className="leading-none mb-3 !px-1">Café Charlevoix</h3>
                    </div>
                    <div className="py-4 px-6">
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Ecocup consignable - 2$</b></p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Buddies</b><br/>5$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Latté Glacé</b><br/>5$</p>
                      <p className="mb-4 !px-2 !text-green-wolf2024"><b>Matcha Limo</b><br/>5$</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {backTopTopButton()}
          </div>

        </div>

        <h2>Pop-up bouffe</h2>
        <p>Un petite fringale ? Les Pop up Bouffe surgissent un peu partout dans la ville à n’importe quelle heure de la journée pour le plus grand plaisir des épicurien·nes et des affamé·es. En mode brunch, buffet ou munchies, les restaurateurs et restauratrices invité·es te boucheront certainement un coin. Surveille bien l’application du Festif! pour découvrir l’endroit et l’heure du prochain Pop up Bouffe. Bon appétit !</p>

        {/* <div>
        <div className="max-w-[2048px] max-h-[767px] pb-6">
          <StaticImage src="../../images/menu.png" alt="Image représentant un kiosque de boisson" imgClassName="rounded-3xl"/>
        </div>

        <h2>Parvis</h2>
        <div className='ml-4'>
            <h3>Boissons</h3>
            <div className='ul-container'>
            <ul>
                <li><p>Bière de la MicroBrasserie Charlevoix</p></li>
                <li><p>Choix de vin</p></li>
                <li><p>Prêt-à-boire Menaud</p></li>
                <li><p>Bière sans-alcool</p></li>
                <li><p>Café Charlevoix</p></li>
                <li><p>Famille Migneron - Charlevoyou</p></li>
            </ul>
            </div>

            <h3>Bouffe (menu à venir)</h3>
            <div className='ul-container'>
            <ul>
                <li><p>Ah La vache</p></li>
                <li><p>Tony & Charlo</p></li>
                <li><p>Faux Bergers</p></li>
                <li><p>Les Viandes Bio de Charlevoix</p></li>
                <li><p>Bistrot des artistes</p></li>
            </ul>
            </div>

        </div>

        <h2>Place Desjardins</h2>
        <div className='ml-4'>
            <h3>Boissons</h3>
            <div className='ul-container'>
            <ul>
                <li><p>Bière de la MicroBrasserie Charlevoix</p></li>
                <li><p>Choix de vin du Québec</p></li>
                <li><p>Famille Migneron - Charlevoyou</p></li>
                <li><p>Bière sans-alcool</p></li>
            </ul>
            </div>

            <h3>Bouffe (menu à venir)</h3>
            <div className='ul-container'>
            <ul>
                <li><p>Faux Bergers </p></li>
            </ul>
            </div>

        </div>

        <h2>Rue Festive</h2>
        <div className='ml-4'>
            <h3>Boissons</h3>
            <div className='ul-container'>
            <ul>
                <li><p>Bière de la MicroBrasserie Charlevoix</p></li>
                <li><p>Famille Migneron - Charlevoyou</p></li>
                <li><p>Choix de vin</p></li>
                <li><p>Bière sans-alcool</p></li>
            </ul>
            </div>

            <h3>Bouffe (menu à venir)</h3>
            <div className='ul-container'>
            <ul>
                <li><p>Auberge La Secousse</p></li>
                <li><p>Hôtel & Spa Le Germain Charlevoix</p></li>
            </ul>
            </div>
        </div>

        <a href="/scenes/pop-up-bouffe"><h2>Pop-up bouffe</h2></a>
        <p>Un petite fringale ? Les Pop up Bouffe surgissent un peu partout dans la ville à n’importe quelle heure de la journée pour le plus grand plaisir des épicurien.nes et des affamé.es. En mode brunch, buffet ou munchies, les restaurateurs et restauratrices invité.es te boucheront certainement un coin. Surveille bien l’application du Festif! pour découvrir l’endroit et l’heure du prochain Pop up Bouffe. Bon appétit !</p>
      </div> */}

          </div>
      </div>
    </Wolflayout>
  )
}

export default Menu
